import MuiBox, { type BoxProps as MuiBoxProps } from '@mui/material/Box';
import { forwardRef } from 'react';
export interface BoxProps extends MuiBoxProps {
  'aria-label'?: string;
  'data-test-id'?: string;
  ref?: React.Ref<HTMLElement>;
}
export const Box = forwardRef<HTMLElement, BoxProps>(({
  'aria-label': ariaLabel,
  'data-test-id': dataTestId,
  children,
  onClick,
  ...rest
}, ref) => {
  return <MuiBox ref={ref} aria-label={ariaLabel} data-test-id={dataTestId} onClick={onClick} {...rest}>
        {children}
      </MuiBox>;
});